@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
/* purgecss start ignore */

header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* Applying CSS for CTA */

.cta {
  margin-top: 2.5rem;
  /*to push buttons littlebit down*/
  display: flex;
  grid-gap: 1.2rem;
  gap: 1.2rem;
  justify-content: center;
}

/* Applying CSS for HeaderSocials */

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* Applying CSS for My image */

.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 5rem 1.5rem 1, 5rem 1.5rem;
}

/* Applying CSS for Scroll Down */

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* Media queries for medium devices(Tablets) */

@media screen and (max-width: 1024px) {
  header {
    height: 60vh;
  }
}

/* Media queries for small devices(Mobiles) */

@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }

  .header__socials,
  .scroll__down {
    display: none;
  }

  .me>img {
    margin-top: 2.5rem;
  }
}

/* purgecss end ignore */

/*purgecss start ignore*/

nav{
  background: rgba(0, 0, 0, 0.3);
  width: -webkit-max-content;
  width: max-content; /*get the width of it's content*/
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  display: flex;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  border-radius: 3rem;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}

nav a{
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  color: var(--color-light);
  font-size: 1.1rem;
}

nav a:hover{
  background: rgba(0, 0, 0, 0.4);
}

nav a.active{
  background: var(--color-primary);
  color: var(--color-bg);
}

/*purgecss end ignore*/

/* purgecss start ignore */

.about__container{
  display: grid;
  grid-template-columns: 30% 50%;
  grid-gap: 15%;
  gap: 15%;
}

.about__me{
  width: 90%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(-45deg, transparent, var(--color-primary), transparent);
  display: grid;
  place-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.about__me-image{
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(-10deg);
  transition: var(--transition);
}

.about__me-image:hover{
  transform: rotate(0);
}

.about__cards{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.about__card{
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: .6rem;
  text-align: center;
  transition: var(--transition);
}

.about__card:hover{
  background: transparent;
  border-color: var(--color-primary);
  cursor: default;
}

.about__card__title {
  display: block;
  font-size: 1.1em; /* slightly larger than the description */
  color: #ffd700; /* a more eye-catching color, e.g., a shade of yellow from your logo */
  margin-bottom: 0.5em; /* adds some space below the title */
  padding: 0.2em 0; /* optional: adds padding to make the title stand out more */
}

.about__card__description {
  font-size: 0.9em;
  color: #cccccc; /* Light gray for sufficient contrast on a dark gray background */
  display: block;
}

.about__icon{
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about__card h5{
  font-size: 0.95rem;
}

.about__card small{
  font-size: .7rem;
  color: var(--color-light);
}

.about__content p{
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}

ul li {
  border-bottom: 1px solid #ccc; /* Replace #ccc with the color of your choice */
  padding-bottom: 10px; /* Adjust padding to your preference */
  margin-bottom: 10px; /* Adjust margin to create space between the border and next item */
}

ul li:last-child {
  border-bottom: none; /* Removes the border from the last item */
}

/* Media queries for medium devices(Tablets) */

@media screen and (max-width: 1024px) {
  .about__container{
    grid-template-columns: 1fr;
    grid-gap: 0;
    gap: 0;
  }

  .about__me{
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about__content p{
    margin: 1rem 0 1.5rem;
  }
}

/* Media queries for small devices(Mobiles) */

@media screen and (max-width: 600px) {
  .about__me{
    width: 65%;
    margin: 0 auto 3rem;
  }

  .about__cards{
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .about__content{
    text-align: center;
  }

  .about__content p{
    margin: 1.5rem 0;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.experience__container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  gap: 2rem;
}

.experience__container > div{
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience__container > div:hover{
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experience__container > div h3{
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experience__content{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  gap: 2rem;
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.experience__details{
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
}

.experience__details-icon{
  margin-top: 6px;
  color: var(--color-primary);
}

/* Media queries for medium devices(Tablets) */

@media screen and (max-width: 1024px) {
  .experience__container{
    grid-template-columns: 1fr;
  }

  .experience__container > div{
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .experience__content{
    padding: 1rem;
  }
}

/* Media queries for small devices(Mobiles) */

@media screen and (max-width: 600px) {
  .experience__container{
    grid-gap: 1rem;
    gap: 1rem;
  }

  .experience__container > div{
    width: 100%;
    padding: 2rem 1rem;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.portfolio__container{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 2.5rem;
  gap: 2.5rem;
}

.portfolio__item{
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolio__item:hover{
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portfolio__item-image{
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3{
  margin: 1.2rem 0 .5rem;
}

.portfolio__item-cta{
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
}

/* Media queries for medium devices(Tablets) */

@media screen and (max-width: 1024px) {
  .portfolio__container{
    grid-template-columns: 1fr 1fr; /*50% from left 50% from right  */
    grid-gap: 1.2rem;
    gap: 1.2rem;
  }
}

/* Media queries for small devices(Mobiles) */

@media screen and (max-width: 600px) {
  .portfolio__container{
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    gap: 1rem;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.container.contact__container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact__options{
  display: flex;
  flex-direction: column;
  grid-gap: 1.2rem;
  gap: 1.2rem;
}

.contact__option{
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}

.contact__option:hover{
  background: transparent;
  border-color: var(--color-primary-variant);
}

.contact__option-icon{
  font-size: 1.5rem;
  margin-bottom: .5rem;
}

.contact__option a{
  margin-top: .7rem;
  display: inline-block;
  font-size: .8rem;
}

/* Form Part */

/*
form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input, textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: .5rem;
  background: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
}
*/

/* Media queries for medium devices(Tablets) */

@media screen and (max-width: 1024px) {
  .container.contact__container{
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    gap: 2rem;
  }
}

/* Media queries for small devices(Mobiles) */

@media screen and (max-width: 600px) {
  .container.contact__container{
    width: var(--container-width-sm);
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

footer {
  background: var(--color-primary);
  padding: 3rem 0;
  text-align: center;
  font-size: .9rem;
  margin-top: 7rem;
}

footer a {
  color: var(--color-bg);
}

.footer__logo {
  width: 100px;
  /* Adjust this value to scale your image */
  height: auto;
  /* This ensures the aspect ratio is maintained */
  margin-bottom: 2rem;
  display: inline-block;
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  margin: 0 auto 3rem;
}

.footer__socials {
  display: flex;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 4rem;
}

.footer__socials a {
  background: var(--color-bg);
  color: var(--color-white);
  padding: .8rem;
  border-radius: .7rem;
  display: flex;
  border: 1px solid transparent;
}

.footer__socials a:hover {
  background: transparent;
  color: var(--color-bg);
  border-color: var(--color-bg);
}

.footer__copyright {
  margin-bottom: 4rem;
  color: var(--color-bg);
}

/* Media queries for small devices(Mobiles) */

@media screen and (max-width: 600px) {
  .permalinks {
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .footer__socials {
    margin-bottom: 2.6rem;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

/* defining some css variables */

:root {
  --color-bg: #333333;
  /* A darker gray for background */
  --color-bg-variant: #4d4d4d;
  /* A lighter variant of gray for background */
  --color-primary: #ffd700;
  /* Assuming this is the yellow from your logo */
  --color-primary-variant: rgba(255, 215, 0, 0.4);
  /* Yellow with transparency for variants */
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);
  --container-width-lg: 70%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
}

/* removing the scrollbar */

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Poppins', sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  background-image: url(/static/media/darth-stripe.8e67525e.png);
}

/* General Styles */

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
  /*position in the middle horizontally */
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
  /*Having some white space around different section*/
}

section>h2,
section>h5 {
  text-align: center;
  color: var(--color-light);
}

section>h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: -webkit-max-content;
  width: max-content;
  /* fit exactly it's content */
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* Media queries for medium devices(Tablets) */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

/* Media queries for small devices(Mobiles) */

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section>h2 {
    margin-bottom: 2rem;
  }
}

/* purgecss end ignore */

