.about__container{
  display: grid;
  grid-template-columns: 30% 50%;
  gap: 15%;
}

.about__me{
  width: 90%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(-45deg, transparent, var(--color-primary), transparent);
  display: grid;
  place-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.about__me-image{
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(-10deg);
  transition: var(--transition);
}

.about__me-image:hover{
  transform: rotate(0);
}

.about__cards{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.about__card{

  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: .6rem;
  text-align: center;
  transition: var(--transition);
}

.about__card:hover{
  background: transparent;
  border-color: var(--color-primary);
  cursor: default;
}

.about__card__title {
  display: block;
  font-size: 1.1em; /* slightly larger than the description */
  color: #ffd700; /* a more eye-catching color, e.g., a shade of yellow from your logo */
  margin-bottom: 0.5em; /* adds some space below the title */
  padding: 0.2em 0; /* optional: adds padding to make the title stand out more */
}

.about__card__description {
  font-size: 0.9em;
  color: #cccccc; /* Light gray for sufficient contrast on a dark gray background */
  display: block;
}


.about__icon{

  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about__card h5{

  font-size: 0.95rem;
}

.about__card small{

  font-size: .7rem;
  color: var(--color-light);
}

.about__content p{

  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}

ul li {
  border-bottom: 1px solid #ccc; /* Replace #ccc with the color of your choice */
  padding-bottom: 10px; /* Adjust padding to your preference */
  margin-bottom: 10px; /* Adjust margin to create space between the border and next item */
}

ul li:last-child {
  border-bottom: none; /* Removes the border from the last item */
}


/* Media queries for medium devices(Tablets) */

@media screen and (max-width: 1024px) {

  .about__container{

      grid-template-columns: 1fr;
      gap: 0;
  }

  .about__me{

      width: 50%;
      margin: 2rem auto 4rem;
  }

  .about__content p{

      margin: 1rem 0 1.5rem;
  }


}

/* Media queries for small devices(Mobiles) */

@media screen and (max-width: 600px) {

  .about__me{

      width: 65%;
      margin: 0 auto 3rem;
  }

  .about__cards{

      grid-template-columns: 1fr 1fr;
      gap: 1rem;
  }

  .about__content{

      text-align: center;
  }

  .about__content p{

      margin: 1.5rem 0;
  }
}
